<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Notifications')" class="pb-0"></block-head>
      <block-content>
        <div class="nk-block-between mb-2 mt-3">
          <div>
            <a href="javascript:;" v-on:click="markAsRead('all')">{{$t('Mark All as Read')}}</a>
          </div>
          <block-tools>
            <li>
              <drop-down title="" trigger-btn-classes="btn-outline-light btn-white dropdown-indicator" :trigger-btn-text="statusFilter === 'all' ? $t('Status') : statusFilter">
                <ul class="link-check">
                  <li :class="{active: statusFilter === 'all'}"><a href="javascript:;" v-on:click="[statusFilter='all', getNotifications(true)]">{{$t('All')}}</a></li>
                  <li :class="{active: statusFilter === 'read'}"><a href="javascript:;" v-on:click="[statusFilter='read', getNotifications(true)]">{{$t('Read')}}</a></li>
                  <li :class="{active: statusFilter === 'unread'}"><a href="javascript:;" v-on:click="[statusFilter='unread', getNotifications(true)]">{{$t('Unread')}}</a></li>
                </ul>
              </drop-down>
            </li>
            <li>
              <drop-down icon="ni ni-setting" title="" size="xs" direction="right" trigger-btn-classes="btn-outline-light btn-white" :dot="order !== 'desc' ? 'primary' : ''">
                <ul class="link-check">
                  <li><span>{{$t('Order')}}</span></li>
                  <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getNotifications(true)]">{{$t('Latest first')}}</a></li>
                  <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getNotifications(true)]">{{$t('Oldest first')}}</a></li>
                </ul>
              </drop-down>
            </li>
          </block-tools>
        </div>
        <div v-if="pagination.page === 1 && spinner" class="text-center">
          <ion-spinner></ion-spinner>
        </div>
        <card no-padding v-else>
          <div class="nk-notification-item dropdown-inner"
               v-for="(n,index) in notifications"
               :key="index"
               :class="{'is-unread': !n.read_at}"
               v-on:click="[notificationAction(n), markAsRead(n)]">
            <div class="nk-notification-icon">
              <nio-icon :icon="iconAndBGColor(n).icon" :class="iconAndBGColor(n).color" class="icon-circle"></nio-icon>
            </div>
            <div class="nk-notification-content">
              <div class="nk-notification-text" v-html="constructMessage(n)"></div>
              <div class="nk-notification-time">{{n.time}} {{$t('ago')}}</div>
            </div>
          </div>
        </card>
        <p v-if="!spinner && notifications.length < 1" class="text-muted h6 border-top pt-4">
          <nio-icon icon="ni-alert"></nio-icon> {{$t('No notification found.')}}</p>
        <div class="mt-4 text-center" v-if="(pagination.page * pagination.perPage) < pagination.totalRecords || spinner">
          <ion-spinner v-if="pagination.page > 1 && spinner"></ion-spinner>
          <a href="javascript:;"
             v-else-if="!spinner"
             v-on:click="paginationCallback">{{$t('Load more')}} {{$t('notifications')}}</a>
        </div>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {IonSpinner} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {MainBlock,BlockContent,BlockHead} from "@core/layouts";
import axios from "@/libs/axios";
import {Card} from "@core/components/cards";

import "bootstrap"

import useNotifications from "./useNotifications"
import NioIcon from "@core/components/nio-icon/NioIcon";
import BlockTools from "@core/layouts/main-block/components/BlockTools";
import DropDown from "@core/components/dropdown/DropDown";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    DropDown,
    BlockTools,
    NioIcon,
    BlockContent,
    BlockHead,
    Card,
    MainBlock,
    IonSpinner,
  },
  setup() {

    let spinner = ref(false)
    let statusFilter = ref('all')
    let order        = ref('desc')
    let notifications     = ref([])

    let unreadCount = ref(0)

    let pagination = reactive({
      page: 1,
      perPage: 10,
      totalRecords: 0,
    })

    const paginationCallback = async () => {
      pagination.page++;
      getNotifications(false)
    }

    const getNotifications = (reset) => {

      if(reset){
        pagination.page = 1
      }
      spinner.value = true
      axios.get('/api/notifications?page='+pagination.page+'&status='+statusFilter.value+'&order='+order.value)
          .then( (resp) => {
            if(reset){
              notifications.value = []
            }
            for(let x in resp.data.data.notifications){
              notifications.value.push(resp.data.data.notifications[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.data.total_count
              unreadCount.value       = resp.data.data.unread_count
            }
          })
          .catch( err => {
            console.log('Notifications.vue ajax error: ', err)
          })
          .then(() => spinner.value = false)
    }

    onMounted(()=> getNotifications())

    const { constructMessage, iconAndBGColor, markAsRead, notificationAction } = useNotifications()

    return {
      constructMessage,
      iconAndBGColor,
      notificationAction,
      markAsRead,
      notifications,
      getNotifications,

      statusFilter,
      order,
      pagination,
      paginationCallback,
      spinner,
    }
  }
});

</script>
